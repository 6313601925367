"use client";

import { Image } from "@/lib/visionary-image";
import { GALLERY_IMAGES } from "@/lib/image-urls";

import styles from "./Gallery.module.scss";

const ALT_TEXT = "Sample image to demonstrate a gallery of Visionary images";

export const Gallery = () => {
  return (
    <>
      {/* <div className={styles.optionsBar}>
        <strong>Image options</strong>
      </div> */}

      <section className={styles.grid}>
        {GALLERY_IMAGES.map((imageUrl) => (
          <Image alt={ALT_TEXT} key={imageUrl} preventSelection src={imageUrl} />
        ))}
      </section>
    </>
  );
};

{
  /* <label className="">
      <input type="checkbox" checked={options.fadeIn} onChange={() => handleToggle("fadeIn")} />
      <span className="label-body">Fade in</span>
    </label>

    <label className="">
      <input
        type="checkbox"
        checked={options.disableImageLayer}
        onChange={() => handleToggle("disableImageLayer")}
      />
      <span className="label-body">Disable image layer</span>
    </label> */
}

// type GalleryOptions = Required<
//   Pick<VisionaryImageProps, "fadeIn" | "disableImageLayer" | "disableBlurLayer">
// >;

// const [options, setOptions] = useState<GalleryOptions>({
//   disableBlurLayer: false,
//   disableImageLayer: false,
//   fadeIn: true,
// });

// const handleToggle = useCallback(
//   (optionName: keyof GalleryOptions) => {
//     setOptions((prevOptions) => ({
//       ...prevOptions,
//       [optionName]: !prevOptions[optionName],
//     }));
//   },
//   [setOptions],
// );
