export const GALLERY_IMAGES = [
  "https://cdn.visionary.cloud/image/cGFkbkhCMWd3QSE1OTYzITM5NzUhMDQ2NDVjIVVKMjheMmtTalthTHFFa29rQ2FMY0ZrcGtDYWVrRGcya0NheSE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/Tkkyek5kQ0pVdyE2MDAwITQwMDAhOTMzNDI4IVU1QVtUSG9mMH10ODJhLHA7TU53MUhPRX1FTlpdUU5eQXQsVyE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/Y3FkNXFzTzlDViEzNjc0ITI0NTQhMDg1NjNhIVU1M1Q7b1I4RDh5Lk06b3l5bFJvSFluaXlCUlF4blc7TU5zOiE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/VURVOGVBdnVPXyE2MDAwITQwMDAhODI4NWM0IVVLODtjcnQ4OVdXVyR4YWREfldBLXRvTU5FV0JXWm9ob2FXVSE0ITQ/md/image.jpg",

  "https://cdn.visionary.cloud/image/bXpaZnpCMHRleiEyNDAwITE2MDEhMTViNjA4IVViNDVTeFJCbT9iYXE3VnhpJGJhVFlvZGFOZjdSbG92YXllOyE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/cWxQVUJ6b1UzSCE3MzYwITQ5MTIhNzNhYzhlIVVEN0NOWXJaSFtNeVV9b2ZrVmYqRDRTeHRqdFIqR1dCUmphTCE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/Y0U2dVNlRl83YiE1MjMzITM0ODkhZGRkZWU4IVV8SExfNlMkTXtheX5xUzRSK2JIJTJSamtXa0NSK1dCb0pqWyE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/UnZWUmdnNHY0VCE2MDAwITQwMDAhYTFhNmE1IVU3OWFIZjRuRDZ-cV80RGpXQSVmNFUlTS43OUc5Ri07dDhEKiE0ITQ/md/image.jpg",

  "https://cdn.visionary.cloud/image/TWpGbVJsM1QySyE0NTYzITMwNDIhMjk2OThhIVVDM2x7X3BkWDN0UXBNYmNhZG9mUS1aI2l7VkBWcm5ob0xXQSE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/V3NjWVJ1VUlFYiE0MDAwITI2NzAhOWQ5Y2RkIVV6QnBue2pDUmliST9LbmhWQGF9JTRvZ2F5ZjVzPW9nb01qcyE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/dFQ4NEZxeEp4ciEzMjAwITIxMzYhODQ3MDc0IVUyNVguT3BmMEslMWtaTXxNX29iMDB3cn5DSXBOWnRSLVhXRiE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/V2NvQzBDZjZMSSEyNzM4ITE4MjUhNzI0ZDU0IVU0NjdXWn5DJU54Yy1wJTIkK3M7MHg5c05GTlo5QEluRWVFeCE0ITQ/md/image.jpg",

  "https://cdn.visionary.cloud/image/cUJZa1h0RkpEbyE1MTg0ITM0NTYhOWQ1MjU5IVU5NywrOXNsRTJ4YkVNU2RzLWEkMHlhfiQlUiV9c3dOST1vYiE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/UWpQNWdVTUJ1QSEzMDAwITIwMDAhNWU2ZDg5IVVEOEUjO28jUmlvZz9kal1XQmpbdDhqW2F5ZlF0U2pbaltmayE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/NHNMU1I0ak44cyEzODcyITI1ODEhMDY0Yzc0IVVKMC0uR2YrWn1mNmxDZitlbGY2a0BmK2UsZS5rWWYrZmtlLSE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/RHJXSWozNkdPciE0OTAxITMyNjIhN2Q2ODRjIVU0N0I0UUUxOX19PzVzd0gtUTl9SD10bUlWcj14VSVNVkBheCE0ITQ/md/image.jpg",

  "https://cdn.visionary.cloud/image/MWhidUppbjRpcyExOTIwITEyODAhNWM4OGFmIVVEOVtHaS0_RE5SJVBFa1huI2p0MDBJLiVOb2Y9XnI9TkhXcCE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/VUk5YkFfeFpvcCE1Nzg1ITM4NTchMzIxMjQ4IVUxM1I7N3tzK2t0QissLHh4endrRHpLT1hXc0dHREV4STpOcyE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/a0hTa2pvTmV2ZyEzMDAwITIwMDAhNWFiMWM0IVVBNzJ0NVFtNFR1NCU3UjNPbHQ5RDRjVS5BbW5NW3BKdi5STiE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/aVVYVTB5TDJsNiE0NzE2ITMxNDQhMGUyNjZjIVUzMmlyNVZub2xqVnNeYmVlKmJkeHdXQW8jakV0V29nV0FvaCE0ITQ/md/image.jpg",

  "https://cdn.visionary.cloud/image/MGNPcmhsVHRqNiE2MDAwITQwMDAhY2FiMjljIVVYRDl1RTU5cytvTD93SXJmNW9MRW94WW4rV1Y1VyUwc3BSKyE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/R2JwVmFyRnZUUyE2MDAwITQwMDAhZDE3ZGJkIVVLRkVzUn1KOVdGcC5BV1VafSNARlpFSk5aJTRuU01fSTpPcSE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/U1VSOW5udEd4RCE1MDAwITMzMzMhYzY1NjI2IVU3Q05jP31xMCRFMzJAU09OSEVnblFTTT1jJGl2JG5pT3EkJSE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/ZFVBQllkSkVsZiE2MjQyITQxNjEhY2ViNGFlIVVPRW9WOzl0RCUlMn5XSW9EJXQ2WDlzLkUxSXBYVCUxUmpJbyE0ITQ/md/image.jpg",

  // tall boys
  "https://cdn.visionary.cloud/image/Qmp5Wnlja2VlOSEyNjY5ITQwMDAhZGM4Yzc1IVVTQXZMandeSTpSLX1Ac2xJO1IuXjRzb05lUiotVnM6UipXViE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/N3ZhRHlld0pYUCE1MDc5ITc2MTUhYjk4NjhlIVU0Q3M1RzFpMDA_XjByJTFSNVZiOWgkbHhZS2gwMUpCP11IQCE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/S0FsdDgwdjlNMCEzNDU2ITUxODQhYWY0OTU4IVU3OVB2ZXhiYkkkKjFHV3Jqc051fFhOdFMyc1M9Iyw_c29qWyE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/czdIWG1lUG9VcCEzNzkwITU2ODUhYjVkMWJkIVVtQn0yTFdCTXtqWz9dUmpSam9mJU1SUG9mdDdmbFJqdDd0NyE0ITQ/md/image.jpg",

  "https://cdn.visionary.cloud/image/a2Y5enVIc3J2RCEzNDExITUxMTYhOThiOGJmIVV1RTQ0RG8jV0Jvej9eb2ZvZmpbeHVWQGF5b2ZNe2Zsa0NXQiE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/MjQyc0paZDhPTyE0MDAwITYwMDAhOTk3NTcyIVU2N0FfSiUySTt4YWcrYklXVWJJMEtOR3hZTmFtKm5-c3BzbiE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/Z2Qzd1BaTlRYbiEzNDU1ITUxODIhOGU3YzdiIVU3QWNsRXM7d0h3SDl2UmpYQVIqMDBORi1xU2h-QW9jbn1zbCE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/ZGhoTHhsdmJrQSEzNzQ0ITU2MTYhNDkzNDQ0IVUwNHdedG41MHYlM313c1dJLnM7MGNnMlg3c3AlM0lvdDdheiE0ITQ/md/image.jpg",

  "https://cdn.visionary.cloud/image/T2g1RDFhU3E0QSE1MzA0ITc5NTIhOTQ5Nzk0IVU5RkZ5LXRSfnF-cElVUmp4YVJpP2JvZkUxTXt0N01fdDd0NiE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/UnN0SVFIRFBPMiEzNjQ4ITU0NzIhYTM5NWJmIVU3Ny1ObU1fMDB4YmNHV0FycmtEMDB4Yn5YTkdMfXQ4WC1WPyE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/Y3g5MEtxbWlwYyE0MDAwITYwMDAhMmI3ZTk0IVU1NUZ0T0QqcUclIz1WUlBUI3lEVnNid1JscldvZ1JRUk9pXyE0ITQ/md/image.jpg",
  "https://cdn.visionary.cloud/image/djF2VVhHdVpzOCE0MDAwITYwMDAhOTBhZGRlIVVqSGZNUk9GYX50OHh8YklXVmpdNVFXVmpAV1YtQWF5anVvTCE0ITQ/md/image.jpg",
];
